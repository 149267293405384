<template>
    <transition :name="transition" mode="out-in">
        <div class="modal" v-show="modelValue" :class="{ float: isFloat }">
            <div
                class="modal-background"
                :class="{ float: isFloat }"
                @click.self="turnOffModal"
            >
                <div
                    class="modal-area modal-size"
                    :class="{ float: isFloat }"
                    :style="modalWidth"
                    ref="content"
                >
                    <div v-show="title.length !== 0" class="modal-title">
                        <div class="title">
                            <div class="title-content">
                                {{ title }}
                                <span v-if="isLoading" class="loading">
                                    <span v-text="`.`" />
                                    <span v-text="`.`" />
                                    <span v-text="`.`" />
                                </span>
                                <li v-if="isSuccess" class="success-icon" />
                                <slot name="modal-title-icon" />
                            </div>
                            <XButton
                                class="x-button"
                                @click.prevent="turnOffModal"
                            />
                        </div>
                        <div>
                            <slot name="modal-title" />
                        </div>
                    </div>
                    <div class="modal-content">
                        <slot name="modal-content" />
                    </div>
                    <div
                        v-show="!!$slots['modal-bottom']"
                        :class="[
                            'modal-bottom',
                            'to-right',
                            { shadow: isShowScrollBar }
                        ]"
                    >
                        <slot name="modal-bottom" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import XButton from '@/components/XButton.vue'

export default {
    name: 'Modal',
    emits: ['update:modelValue'],
    components: {
        XButton
    },
    props: {
        // modelValue means isShowModal
        modelValue: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        width: {
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isFloat: {
            type: Boolean,
            default: false
        },
        transition: {
            type: String,
            default: 'fade'
        },
        floatPosition: {
            type: Object,
            default: function () {
                return {
                    top: 0,
                    left: 0
                }
            }
        }
    },
    computed: {
        modalWidth: function () {
            const style = {}
            if (this.width) style['width'] = this.width
            return style
        }
    },
    mounted() {
        this.$nextTick(() => {
            const content = this.$refs.content
            let domDetector = new ResizeObserver(this.setHasScrollBar)
            domDetector.observe(content)
        })
    },
    data() {
        return {
            isShowScrollBar: false
        }
    },
    watch: {
        modelValue: {
            handler: function () {
                //顯示modal禁止背景scroll
                const body = document.querySelector('body')
                if (this.modelValue && !this.$isMobileHorizontal()) {
                    this.$addAllClass(body, 'not-scroll')
                    if (this.isFloat) {
                        this.$removeAllClass(body, 'not-scroll')
                    }
                } else {
                    this.$removeAllClass(body, 'not-scroll')
                }

                if (this.modelValue) {
                    //顯示modal，modal裡內容scroll至最上方
                    this.$nextTick(() => {
                        const modalSize = this.$el.querySelector('.modal-size')
                        modalSize.scrollTo({
                            top: 0
                        })
                    })
                    //當isFloat，點擊Modal外的地方關閉Modal
                    if (this.isFloat) {
                        setTimeout(() => {
                            document.addEventListener(
                                'click',
                                this.setVisibleModal
                            )
                        }, 300)
                    }
                } else {
                    document.removeEventListener('click', this.setVisibleModal)
                }
            },
            immediate: true
        }
    },
    methods: {
        turnOffModal: function () {
            this.$emit('update:modelValue', false)
        },
        setHasScrollBar: function () {
            let scrollHeight,
                clientHeight = 0
            this.$nextTick(() => {
                const content = this.$refs.content
                scrollHeight = content?.scrollHeight
                clientHeight = content?.clientHeight
                this.isShowScrollBar = scrollHeight > clientHeight
            })
        },
        setVisibleModal: function (event) {
            if (!this.$el.contains(event.target)) this.turnOffModal()
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    @media screen and (min-width: 577px) {
        &.float {
            // z-index: 1;
            width: fit-content;
            height: fit-content;
        }
    }
}

.modal-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5%;
    box-sizing: border-box;
    border-radius: 0px;
    @media screen and (min-width: 577px) {
        &.float {
            background-color: $primary-white;
            padding: 0;
            border-radius: 4px;
            width: fit-content;
            height: fit-content;
        }
    }

    @media screen and (max-width: 576px) {
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: Max(20px, env(safe-area-inset-top, 20px));
        padding-bottom: Max(20px, env(safe-area-inset-bottom, 20px));
    }
    @media screen and (max-height: 576px) {
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: Max(20px, env(safe-area-inset-top, 20px));
        padding-bottom: Max(20px, env(safe-area-inset-bottom, 20px));
    }
    @media screen and (orientation: landscape) {
        align-items: center;
        padding-top: Max(20px, env(safe-area-inset-top, 20px));
        padding-bottom: Max(20px, env(safe-area-inset-bottom, 20px));
        padding-left: Max(20px, env(safe-area-inset-left, 20px));
        padding-right: Max(20px, env(safe-area-inset-right, 20px));
    }
}

.modal-area {
    padding: 20px 0;
    box-sizing: border-box;
    background-color: $primary-white;
    border-radius: 4px;
    overflow-x: auto;
    overflow-y: auto;

    //scrollbar_chrome、safari、edge
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: 0px 0px 0px 2px $primary-white inset;
        background-color: $scrollbar-thumb-grey;
        border-radius: 10px;
    }
    //firefox
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-grey transparent;

    @media screen and (max-width: 576px) {
        padding: 20px 0;
    }
    @media screen and (max-height: 576px) {
        padding: 20px 0;
    }
}

.modal-size {
    width: 540px;
    max-height: 100%;
    max-height: calc(
        100% - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0)
    );
    scroll-padding: auto;
    box-shadow: none;
    @media screen and (min-width: 576px) {
        &.float {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
        }
    }

    @media screen and (max-width: 576px) {
        width: 100%;
    }
}

.modal-title {
    position: sticky;
    margin-top: -20px;
    top: -20px;
    width: 100%;
    background-color: $primary-white;
    color: $fourth-black;
    padding: 20px;
    box-sizing: border-box;
    z-index: 5;

    @media screen and (max-width: 576px) {
        padding: 20px;
        margin-top: -20px;
        top: -20px;
    }
    @media screen and (max-height: 576px) {
        padding: 20px;
        margin-top: -20px;
        top: -20px;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;

        .to-left {
            flex-wrap: nowrap;
        }

        @media screen and (max-width: 576px) {
            align-items: flex-start;
            margin-bottom: 10px;
        }
        @media screen and (max-height: 576px) {
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }

    .success-icon {
        display: inline-block;
        margin-bottom: -5px;
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }
    .x-button {
        margin: -8px 0px 0 0;
        @media screen and (max-width: 576px) {
            margin: 0 0 0 0;
        }
        @media screen and (max-height: 576px) {
            margin: 0 0 0 0;
        }
    }
}

.modal-content {
    box-sizing: border-box;
    padding-bottom: 3px;
    padding: 0 20px;
}

.modal-bottom {
    position: sticky;
    bottom: -20px;
    width: 100%;
    background-color: $primary-white;
    margin-bottom: -20px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 4;

    @media screen and (max-width: 576px) {
        padding: 20px;
        margin-bottom: -20px;
        bottom: -20px;
    }
    @media screen and (max-height: 576px) {
        padding: 20px;
        margin-bottom: -20px;
        bottom: -20px;
    }
}

.success-modal {
    .modal-width-540 {
        min-height: 0px;
        padding: 40px 0px 30px 0px;
        margin: 30vh auto;
    }

    .modal-content {
        display: flex;
        justify-content: center;

        .success-message {
            .success-icon {
                width: 54px;
                height: 54px;
                margin-bottom: 10px;
            }

            .success-word {
                color: $seventh-black;
                font-size: 14px;
                letter-spacing: 0.4px;
                line-height: 21px;
            }
        }
    }
}

.loading {
    margin-left: 5px;
    span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
.shadow {
    box-shadow: 0px -2px rgba(0, 0, 0, 0.08);
}
</style>
